.conf-step__seances-movie-thumbnail {
  display: none;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  pointer-events: none;
  width: 120px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  left: 533px;
  top: 679px;
}

.conf-step__seances-timeline .conf-step__movie-poster {
  max-width: 100px;
  min-width: 30px;
  width: 100%;
}