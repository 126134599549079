.movie {
  position: relative;
  padding: 1.5rem;
  margin-top: 3rem;
  background: rgba(241, 235, 230, 0.95);
  color: #000000;
}

.movie__info {
  display: flex;
}

.movie__poster {
  position: relative;
  width: 12.5rem;
  height: 17.5rem;
}

.movie__poster-image {
  position: relative;
  top: -3rem;
  width: 12.5rem;
  height: 17.5rem;
}

.movie__poster::after {
  content: '';
  display: block;
  position: absolute;
  right: -0.7rem;
  top: -3rem;
  border: 1.5rem solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 0 solid transparent;
  border-left: 0.7rem solid #772720;
}

.movie__description {
  flex-grow: 1;
  padding-left: 1.5rem;
}

.movie__title {
  font-weight: 700;
  font-size: 1.6rem;
}

.movie__synopsis {
  font-size: 1.4rem;
  margin-top: 1rem;
}

.movie__data {
  display: flex;
  gap: 5px;
  flex-direction: column;
  font-size: 1.4rem;
  font-weight: 300;
  margin-top: 1rem;
}

.movie-seances__hall+.movie-seances__hall {
  margin-top: 2rem;
}

.movie-seances__hall-title {
  font-weight: 700;
  font-size: 1.6rem;
}

.movie-seances__list {
  margin-top: 0.7rem;
  font-size: 0;
}

.movie-seances__time-block {
  display: inline-block;
  margin-bottom: 4px;
}

.movie-seances__time-block:nth-last-of-type(n + 1) {
  margin-right: 4px;
}

.movie-seances__time {
  display: block;
  padding: 8px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  border: none;
  background-color: #FFFFFF;
  color: #000000;
  text-decoration: none;
  font-size: 1.5rem;
  cursor: pointer;
}