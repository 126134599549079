@charset "UTF-8";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Roboto", sans-serif;
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  background-image: url(../public/i/background.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: right;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

.app-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 120px;
}

.admin-container {
  background-image: url(../public/i/background2.jpg);
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
  background-size: cover;
  background-attachment: fixed;
  counter-reset: num;
}

.page-container {
  flex: 1;
  padding: 0 15px;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: white;
}

.text-center {
  text-align: center;
}

@media screen and (min-width: 990px) {
  .page-container {
    width: 990px;
    margin: auto;
    padding: 0;
  }
}
