.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
}

.modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  color: #000000;
  word-break: break-word;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background: white;
}

.modal h2 {
  font-size: 2rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 12px;
}

.modal p {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 0;
}

.modal input,
.modal textarea {
  display: block;
  width: 100%;
  margin-top: 1px;
  padding: 8px;
  font-size: 1.6rem;
  color: #000000;
  border: 1px solid #b7b7b7;
}

.modal input:focus,
.modal textarea:focus {
  outline: 1px #CF87FF solid;
  outline-offset: -1px;
}

.modal .modal__buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.modal button {
  margin-right: 10px;
}