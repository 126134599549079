.buying {
  background-color: rgba(241, 235, 230, 0.95);
  padding-bottom: 3rem;
}

.buying__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
}

.buying__info .buying__info-description {
  padding-right: 1.5rem;
}

.buying__info .buying__info-title,
.buying__info .buying__info-hall {
  font-size: 1.6rem;
  font-weight: 700;
}

.buying__info .buying__info-start,
.buying__info .buying__info-date,
.buying__info .buying__info-total-cost {
  font-size: 1.4rem;
  font-weight: 300;
  margin: 0.7rem 0;
}

.buying__info .buying__info-hint {
  position: relative;
  font-weight: 300;
  font-size: 1.4rem;
  text-align: center;
}

.buying__info .buying__info-hint p {
  position: relative;
  width: 10rem;
}

.buying__info .buying__info-hint p::before {
  content: '';
  position: absolute;
  left: -1rem;
  top: calc(50% - 1.6rem);
  display: block;
  width: 2.4rem;
  height: 3.2rem;
  background-image: url(../../../public/i/hint.png);
  background-size: 2.4rem 3.2rem;
}

.buying-scheme {
  text-align: center;
  background-color: #171D24;
  padding: 1.5rem 3rem 1.5rem;
}

.buying-scheme__wrapper {
  font-size: 10px;
  display: inline-block;
  background-image: url(../../../public/i/screen.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  padding-top: 3rem;
  min-width: 300px;
}

.buying-scheme__wrapper::before {
  content: '';
}

.buying-scheme__row+.buying-scheme__row {
  margin-top: 4px;
}

.buying-scheme__chair {
  display: inline-block;
  vertical-align: middle;
  width: 2em;
  height: 2em;
  border: 1px solid #525252;
  box-sizing: border-box;
  border-radius: 4px;
}

.buying-scheme__chair:not(:first-of-type) {
  margin-left: 4px;
}

.buying-scheme__chair_disabled {
  opacity: 0;
}

.buying-scheme__chair_standart {
  background-color: #FFFFFF;
}

.buying-scheme__chair_vip {
  background-color: #F9953A;
}

.buying-scheme__chair_taken {
  background-color: transparent;
}

.buying-scheme__chair_selected {
  background-color: #25C4CE;
  box-shadow: 0px 0px 4px #16A6AF;
  transform: scale(1.2);
}

.buying-scheme__legend {
  padding-top: 3rem;
  font-size: 1.4rem;
  font-weight: 300;
  color: #FFFFFF;
  display: flex;
  flex-wrap: nowrap;
  text-align: left;
}

.buying-scheme__legend .col {
  max-width: 25rem;
}

.buying-scheme__legend .col:first-of-type {
  padding-right: 1.5rem;
  margin-left: auto;
}

.buying-scheme__legend .col:last-of-type {
  margin-right: auto;
}

.buying-scheme__legend .buying-scheme__legend-price+.buying-scheme__legend-price {
  margin-top: 1rem;
}

/* Кнопка забронировать */
.acceptin-button {
  display: block;
  margin: 3rem auto 0;
  /* box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.24), 0px 0px 3px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  border: none;
  padding: 12px 57px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.4rem;
  background-color: #16A6AF;
  color: #FFFFFF; */
}

.buying-scheme.fullscreen .buying-scheme__wrapper {
  width: 90%;
  padding-top: 6vw;
  font-size: 2.5vw;
  min-width: 290px;
}


@media screen and (min-width: 990px) {
  .buying__info-hint {
    display: none;
  }
}