.login {
  width: 480px;
  margin: 0 auto;
  position: relative;
  background-color: rgba(234, 233, 235, 0.95);
  user-select: none;
}

.login__header {
  position: relative;
  box-sizing: border-box;
  padding: 16px 104px;
  background-color: #63536C;
  color: #FFFFFF;
}

.login__wrapper {
  padding: 35px 104px;
}

.login__label {
  display: inline-block;
  font-size: 1.2rem;
  color: #848484;
  width: 100%;
  margin-bottom: 0.8rem;
}

.login__title {
  font-size: 2.2rem;
  font-weight: 700;
  text-transform: uppercase;
}

.login__input {
  display: block;
  width: 100%;
  margin-top: 1px;
  padding: 8px;
  font-size: 1.6rem;
  color: #000000;
  border: 1px solid #b7b7b7;
  user-select: text;
}

.login__input:focus {
  outline: 1px #CF87FF solid;
  outline-offset: -1px;
}

.text-center {
  text-align: center;
}

.login__button {
  color: #FFFFFF;
  padding: 12px 32px;
  margin-top: 17px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.24), 0px 0px 3px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  border: none;
  background-color: #16A6AF;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.4rem;
  transition-property: background-color;
  transition-duration: .5s;
  transition-timing-function: ease;
  cursor: pointer;
}

.login__button:hover,
.login__button:focus {
  background-color: #2FC9D2;
  outline: none;
}

.login__button:active {
  position: relative;
  top: 2px;
  background-color: #146C72;
  color: #FFFFFF;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}